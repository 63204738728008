<template>
  <a-drawer
    title="收货地址"
    placement="right"
    :closable="true"
    @close="close"
    :visible="visible"
    width="480"
    :class="[{ 'ant-drawer__have-footer': isConfirm }]"
  >
    <div class="mb-[16px]" v-for="item of addressData" :key="item.id">
      <a-card>
        <template #title>
          <span class="mr-[8px]">默认</span>
          <span>
            <a-switch
              :disabled="item.checked"
              checked-children="开"
              un-checked-children="关"
              v-model:checked="item.checked"
              @change="addressSwitchClick(item)"
            />
          </span>
        </template>
        <template #extra>
          <span class="cursor-pointer">
            <EditOutlined @click="editaddressClick(item)"></EditOutlined>
          </span>
          <span class="ml-[16px] cursor-pointer" v-if="!item.checked">
            <CloseOutlined @click="deleteAddressClick(item)"></CloseOutlined>
          </span>
        </template>
        <div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">公司地址</div>
            <div class="w-7/10">
              {{
                `${item.area.parent.parent ? item.area.parent.parent.name : item.area.parent.name}${
                  item.area.parent.parent ? item.area.parent.name : item.area.name
                }${item.area.parent.parent ? item.area.name : ''}${item.addressDetail}`
              }}
            </div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">联系人</div>
            <div class="w-7/10">{{ item.contactName }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">联系电话</div>
            <div class="w-7/10">{{ item.cellphone }}</div>
          </div>
        </div>
      </a-card>
    </div>
    <div
      class="border-[1px] border-dashed border-[#d9d9d9] text-center curdor-pointer bg-[#fff]"
      @click="addAddressClick"
    >
      <PlusOutlined />
      <span class="ml-[8px]">新建收货地址</span>
    </div>
    <div class="drawer-footer" v-if="isConfirm">
      <a-button type="primary" @click="returnClick">确定</a-button>
    </div>
    <a-drawer :title="leverTwoTitle" placement="right" :closable="true" v-model:visible="leverTwoVisible" width="480">
      <a-form :model="address" :wrapper-col="{ span: 24 }" :rules="addressRules" ref="addressFormRef">
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">公司地址</div>
          <a-form-item name="allArea">
            <e-select-area class="w-full" v-model="address.allArea"></e-select-area>
          </a-form-item>
          <a-form-item name="addressDetail">
            <a-textarea
              placeholder="请输入详细地址"
              :maxlength="20"
              :auto-size="{ minRows: 2, maxRows: 5 }"
              v-model:value="address.addressDetail"
            />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">联系人</div>
          <a-form-item name="contactName">
            <a-input :maxlength="12" v-model:value="address.contactName" placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">联系电话</div>
          <a-form-item name="cellphone">
            <a-input
              :maxlength="12"
              @change="() => (address.cellphone = address.cellphone.replace(/[\D]/g, ''))"
              v-model:value="address.cellphone"
              placeholder="请输入"
            />
          </a-form-item>
        </div>
      </a-form>
      <div class="drawer-footer">
        <a-button class="mr-[8px]" @click="leverTwoVisible = false">取消</a-button>
        <a-button type="primary" :loading="editaddressLoading || createAddressLoading" @click="confirmCLick"
          >确定</a-button
        >
      </div>
    </a-drawer>
  </a-drawer>
</template>

<script lang="ts" setup>
import { PropType, reactive, ref } from 'vue'
import { Card as ACard, Switch as ASwitch, Drawer as ADrawer } from 'ant-design-vue'

import { useAjax } from '@vue-mfe/utils'
import { EditOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons-vue'

// @ts-ignore
const props = defineProps(
  {
    visible: Boolean as PropType<boolean>,
    isConfirm: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
)
const emit = defineEmits(['confirm', 'update:visible'])

interface Address {
  allArea: any[]
  addressDetail: string
  contactName: string
  cellphone: string
  area: any
  id: string
}
const leverTwoVisible = ref(false)
const defaultaddressId = ref('')
const operationType = ref('')
const leverTwoTitle = ref('')

let address = reactive<Address>(
  {
    allArea: [],
    addressDetail: '',
    contactName: '',
    cellphone: '',
    area: {
      code: ''
    },
    id: ''
  }
)
const addressInit = () => {
  address.allArea = []
  address.contactName = ''
  address.cellphone = ''
  address.addressDetail = ''
  address.id = ''
}
const returnClick = () => {
  emit('confirm', addressData.value[0])
}
const addressFormRef = ref()
const addressRules = reactive(
  {
    allArea: {
      // @ts-ignore
      validator: (rule: any, value: any) => {
        if (!value.length) {
          return Promise.reject('请选择地址')
        } else if (value.length < 2) {
          return Promise.reject('请选择合适地址')
        } else {
          return Promise.resolve()
        }
      },
      trigger: 'blur'
    },
    addressDetail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
    contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
    cellphone: [
      { required: true, message: '请输入联系方式', trigger: 'blur' },
      { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号', trigger: 'blur' }
    ]
  }
)
// 获取开票资料
const { data: addressData, run: getAddressData } = useAjax(
  {
    action: `GET /client/address`,
    convert: {
      client (data) {
        data.forEach(
          (item: any) => {
            if (item.status.code === 'Y') {
              item.checked = true
            } else {
              item.checked = false
            }
          }
        )
        return data
      }
    }
  }
)
const addAddressClick = () => {
  addressInit()
  leverTwoTitle.value = '新建收货地址'
  operationType.value = 'new'
  addressFormRef.value?.clearValidate()
  leverTwoVisible.value = true
}

const { run: createAddress, loading: createAddressLoading } = useAjax(
  {
    action: `POST /client/address`,
    params: () => address,
    lazy: true
  }
)
const confirmCLick = () => {
  // if (address.allArea.length < 2) return message.warning('请选择正确地址')
  address.area.code = address.allArea[address.allArea.length - 1]
  addressFormRef.value.validate().then(
    () => {
      if (operationType.value === 'new') {
        createAddress().then(
          async () => {
            getAddressData().then(
              (res) => {
                if (res.status === 200) {
                  leverTwoVisible.value = false
                }
              }
            )
          }
        )
      }
      if (operationType.value === 'edit') {
        editaddress().then(
          () => {
            getAddressData().then(
              (res) => {
                if (res.status === 200) {
                  leverTwoVisible.value = false
                }
              }
            )
          }
        )
      }
    }
  )
}
const { run: editaddress, loading: editaddressLoading } = useAjax(
  {
    action: `PUT /client/address`,
    params: () => address,
    lazy: true
  }
)
const editaddressClick = (item: any) => {
  address = Object.assign(address, { ...item })
  address.allArea = [item.area.parent.code, item.area.code]
  if (item.area.parent.parent) {
    address.allArea.unshift(item.area.parent.parent.code)
  }
  leverTwoTitle.value = '编辑收货地址'
  operationType.value = 'edit'
  addressFormRef.value?.clearValidate()

  leverTwoVisible.value = true
}

const { run: setDefaultaddress } = useAjax(
  {
    action: `PUT /client/address/default/:addressInfoId`,
    params: () => defaultaddressId.value,
    success () {},
    lazy: true
  }
)
const addressSwitchClick = (item: any) => {
  if (item.checked) {
    defaultaddressId.value = item.id
    setDefaultaddress().then(
      () => {
        getAddressData()
      }
    )
  }
}

const { run: deleteAddress } = useAjax(
  {
    action: `DELETE /client/address/:addressId`,
    params: () => defaultaddressId.value,
    lazy: true
  }
)
const deleteAddressClick = (item: Address) => {
  defaultaddressId.value = item.id
  deleteAddress().then(
    () => {
      getAddressData()
    }
  )
}

const close = () => {
  emit('update:visible', false)
}
</script>

<style scoped></style>
